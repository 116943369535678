@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');


/* ======================== ProdutForm Start ======================== */



.admin_content {
    margin: 0 7vmin 10vmin 7vmin;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 1px solid red; */
}

.product_form {
    /* border: 1px solid red; */
    width: 45%;
}

.product_form form {
    margin: 0 auto;
    padding: 20px;
    border-radius: 2px;
    /* border: 1px solid #ddd; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.brand_and_category {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.brand_and_category input[placeholder="Product Brand"] {
    margin-right: 2vmin;
}

.title_and_subtitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.title_and_subtitle input[placeholder="Product Title"] {
    margin-right: 2vmin;
}

.product_form form h1 {
    margin: 0 0 3vmin 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: rgb(0, 132, 255);
}

.product_form form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.product_form form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.product_form form label {
    text-align: left;
    width: 100%;
    padding: 0 0 1vmin 3.5vmin;
    font-size: 1.9vmin;
    color: #808080;
    /* border: 1px solid red; */
}

.product_form form button {
    width: 100%;
    padding: 12px;
    background-color: rgb(0, 132, 255);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: 0.3s ease;
}

.product_form form button:hover {
    background-color: rgba(0, 132, 255, 0.605);
}

.product_form form::placeholder {
    color: #aaa;
}

.product_form form input:focus,
.product_form form textarea:focus {
    outline: none;
    border-color: #66afe9;
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}

/* file upload button */
.product_form form input[type="file"]::file-selector-button {
    border-radius: 3px;
    padding: 0 16px;
    height: 28px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
    font-size: 1.5vmin;
    letter-spacing: 0.5px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    color: #171717;
}

/* file upload button hover state */
.product_form form input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
}

/* file upload button active state */
.product_form form input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
}

/* ------------------------ */

/* default boilerplate to center input */
/* body {
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5vmax;
    box-sizing: border-box;
} */
















/* ======================== ProdutForm End ======================== */


/* ======================== AdminHome Start ======================== */

.admin_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2vmin 7vmin;
    margin-bottom: 4vmin;
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}


/* ======================== AdminHome End ======================== */




/* ======================== LoginForm Start ======================== */


.login_form {
    margin-top: 15vmin;
}


.login_form h1 {

    text-align: center;
    letter-spacing: 1px;
    color: rgb(0, 132, 255);
    font-size: 5.5vmin;
}

.login_form h1 span {

    color: rgb(255, 98, 98);
}

.login_form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin: 5vmin auto;
    /* border: 1px solid red; */
    width: 20%;
}

.login_form form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login_form form input:focus {
    outline: none;
    border-color: #66afe9;
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}

.login_form form button {
    width: 100%;
    padding: 12px;
    background-color: rgb(0, 132, 255);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: 0.3s ease;
    position: relative;
    right: 1.5px;
}

.login_form form button:hover {
    background-color: rgba(0, 132, 255, 0.605);
}

.login_form form button:focus {
    outline: none;
    border-color: #66afe9;
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}






/* ======================== LoginForm End ======================== */





/* ======================== ProductList Start ======================== */

.admin_header {
    color: rgb(0, 132, 255);
}

.admin_header span {
    color: rgb(255, 98, 98);
}

.admin_header button i {
    margin-left: 1vmin;
}

.product_list {
    /* border: 1px solid red; */
    width: 50%;
}

.product_list h1 {
    margin: 1.8vmin 0 4.5vmin 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: rgb(0, 132, 255);
    text-align: center;
    font-size: 5vmin;
}

h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

thead {
    background-color: #f2f2f2;
}

th,
td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

th {
    background-color: rgb(0, 132, 255);
    color: white;
}

button {
    margin-left: 5px;
    cursor: pointer;
    padding: 5px 10px;
    background-color: rgb(0, 132, 255);
    color: white;
    border: none;
    border-radius: 4px;
}

button:hover {
    background-color: rgba(0, 132, 255, 0.605);
}

/* .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
} */

.actions button {
    display: inline-block;
}

.actions button a {
    display: inline-block;
    text-decoration: none;
    color: white;
}

.category {
    text-transform: capitalize;
    /* font-size: 2vmin; */
}

/* ======================== ProductList End ======================== */


.product_list ul button {
    margin: 2vmin 2vmin 2vmin 0;
}

.product_list tbody td button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 1vmin;
    height: 1vmin;
    margin: 2vmin 1.5vmin;
    padding: 2vmin;
}

.admin_header a {
    margin: 2vmin;
    text-decoration: none;
    font-size: 2.8vmin;
    font-weight: 500;
    color: gray;
    letter-spacing: 0.5px;
}

.admin_header a:hover {
    color: rgb(0, 132, 255);
}